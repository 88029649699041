import { navigateTo, registerApp } from '@mfl/framework';
import { currentUser, WEBAPP_BASE_URL } from '@mfl/platform-shell';
import { baseRoute } from './constants';

export async function register() {
  //when uses is in personal-signatures mode there is no domain with settings
  // as well as if user has no permission
  if (
    currentUser.isAllowedTo('domain.manage') &&
    currentUser.accountId !== 'personal-signatures'
  ) {
    await registerApp('settings', baseRoute);
  } else {
    navigateTo(`${WEBAPP_BASE_URL}/home`, false, true);
  }
  // TODO: register to main navigation
}
